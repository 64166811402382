var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Seller Invoices"}},[_c('vue-good-table',{ref:"universal-payment-master-courier",attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"isLoading":_vm.isLoadingProp,"rtl":_vm.direction,"sort-options":{
			enabled: true,
			initialSortBy: {field: 'payment_date', type: 'desc'}
		},"search-options":{
			enabled: true,
			externalQuery: _vm.searchTerm,
		},"totalRows":_vm.totalRecords,"pagination-options":{
			enabled: true,
		}},on:{"on-column-filter":_vm.onColumnFilter,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.testInvoice(props.row['seller_payment_id'])}}},[_vm._v("Test Invoice")]),_c('p'),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.sendInvoice(props.row['seller_payment_id'])}}},[_vm._v("Send Invoice")])],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":_vm.pages},on:{"input":_vm.handlePageChange},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.handleChangePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('b-spinner',{ref:"spinner_one",staticClass:"mr-1",staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary","type":"grow"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }