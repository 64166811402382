<template>




	<b-card title="Seller Invoices">
    
	
		<!-- table -->
		<vue-good-table
			ref="universal-payment-master-courier"
			mode="remote"
			:columns="columns"
			:rows="rows"
			:isLoading="isLoadingProp"
			:rtl="direction"
			:sort-options="{
				enabled: true,
				initialSortBy: {field: 'payment_date', type: 'desc'}
			}"
			:search-options="{
				enabled: true,
				externalQuery: searchTerm,
			}"
			:totalRows="totalRecords"
			@on-column-filter="onColumnFilter"
			:pagination-options="{
				enabled: true,
			}"
			@on-sort-change="onSortChange"
		>

			<div slot="loadingContent">
				<b-spinner ref="spinner_one" variant="primary" class="mr-1" type="grow" style="width: 4rem; height: 4rem" />
			</div>
			
			<template slot="table-row" slot-scope="props">

				<!-- Column: Action -->
				<span v-if="props.column.field === 'action'">
					<b-button variant="primary" size="sm" v-on:click="testInvoice(props.row['seller_payment_id'])">Test Invoice</b-button>
					<p></p>
					<b-button variant="primary" size="sm" v-on:click="sendInvoice(props.row['seller_payment_id'])">Send Invoice</b-button>
				</span>

			</template>

			<!-- pagination -->
			<template slot="pagination-bottom" slot-scope="props">
			
				<div class="d-flex justify-content-between flex-wrap">

					<!-- page length -->
					<div class="d-flex align-items-center mb-0 mt-1">
						<span class="text-nowrap"> Showing 1 to </span>
						<b-form-select v-model="pageLength" :options="pages" class="mx-1" @input="handlePageChange" />
						<span class="text-nowrap"> of {{ props.total }} entries </span>
					</div>

					<!-- pagination -->
					<div>
						<b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="handleChangePage" >
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</div>
				</div>
			</template>


		</vue-good-table>

			
	</b-card>


</template>


<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.18.1/moment.min.js"></script>

<script>
	import {
		BAvatar,
		BBadge,
		BPagination,
		BFormGroup,
		BFormInput,
		BFormSelect,
		BDropdownItem,
		BDropdown,
		BCard,
		BButton,
		BTabs,
		BTab,
		BFormDatepicker,
		BAlert,
		BSpinner,
	} from "bootstrap-vue";
	import { VueGoodTable } from "vue-good-table";
	import { ValidationProvider, ValidationObserver } from "vee-validate";
	import { required } from "@validations";
	import "prismjs";
	import "prismjs/themes/prism-tomorrow.css";
	import Prism from "vue-prism-component";
	import store from "@/store/index";
	import Ripple from "vue-ripple-directive";

	export default {

		components: {
			VueGoodTable,
			BFormDatepicker,
			BAlert,
			BSpinner,
			ValidationProvider,
			ValidationObserver,
			BCard,
			BTab,
			BTabs,
			BAvatar,
			BBadge,
			BPagination,
			BFormGroup,
			BFormInput,
			BFormSelect,
			Prism,
			BDropdownItem,
			BDropdown,
			BButton,
		},

		directives: {
			Ripple,
		},

		
		data() {

			return {
				
				allData: null,
				log: [],
				required,
				pageLength: 10,
				totalRecords: 0,
				get_main_data_url: "get-seller-paid-payment-all",
				dir: false,
				transactionIdState: null,
				alertMessage: 0,
				returnMessageStatus: 0,
				returnMessage: null,
				formatter:new Intl.NumberFormat(),
				isLoadingProp: true,
				dateState: null,
				transaction_id: "",
				newdate: new Date(),
				submittedNames: [],
				pages: ["10", "20", "50", "100"],
				
				columns: [
					{
						label: "Seller ID",
						field: "seller_id",
						filterOptions: {
							styleClass: "class1", // class to be added to the parent th element
							enabled: true, // enable filter for this column
							placeholder: "Filter This Thing", // placeholder for filter input
							filterValue: "", // initial populated value for this filter
							filterDropdownItems: [], // dropdown (with selected values) instead of text input
							filterFn: this.columnFilterFn, //custom filter function that
							trigger: "enter", //only trigger on enter not on keyup
						},
					},
					{
						label: "Seller Name",
						field: "seller_name",
						filterOptions: {
							styleClass: "class1", // class to be added to the parent th element
							enabled: true, // enable filter for this column
							placeholder: "Filter This Thing", // placeholder for filter input
							filterValue: "", // initial populated value for this filter
							filterDropdownItems: [], // dropdown (with selected values) instead of text input
							filterFn: this.columnFilterFn, //custom filter function that
							trigger: "enter", //only trigger on enter not on keyup
						},
					},
					{
						label: "Payment ID",
						field: "transaction_reference_id",
						filterOptions: {
							styleClass: "class1", // class to be added to the parent th element
							enabled: true, // enable filter for this column
							placeholder: "Filter This Thing", // placeholder for filter input
							filterValue: "", // initial populated value for this filter
							filterDropdownItems: [], // dropdown (with selected values) instead of text input
							filterFn: this.columnFilterFn, //custom filter function that
							trigger: "enter", //only trigger on enter not on keyup
						},
					},
					{
						label: "Shipments",
						field: "shipments",
						sortable: false,
					},
					{
						label: "Amount",
						field: "amount",
						sortable: false,
					},
					{
						label: "Courier COD Charges",
						field: (row) => {
							return this.formatter.format(row.invoice_amount + row.diff);
						},
						sortable: false,
					},
					{
						label: "Date",
						field: "payment_date",
						filterOptions: {
							styleClass: "class1", // class to be added to the parent th element
							enabled: true, // enable filter for this column
							placeholder: "Filter This Thing", // placeholder for filter input
							filterValue: "", // initial populated value for this filter
							filterDropdownItems: [], // dropdown (with selected values) instead of text input
							filterFn: this.columnFilterFn, //custom filter function that
							trigger: "enter", //only trigger on enter not on keyup
						},
					},
					{
						label: "Action",
						field: "action",
						sortable: false,
					},
				],
				rows: [],
				serverParams: {
					// a map of column filters example: {name: 'john', age: '20'}
					columnFilters: {},
					sort: {
						field: "payment_date", // example: 'name'
						type: "desc", // 'asc' or 'desc'
					},
					page: 1, // what page I want to show
					perPage: 10, // how many items I'm showing per page
				},
				searchTerm: "",
			};
		},


		computed: {

			direction() {
				if (store.state.appConfig.isRTL) {
					this.dir = true;
					return this.dir;
				}
				this.dir = false;
				return this.dir;
			},

		},


		methods: {



			/// TABLE METHODS START ///

			updateAllData(data) {
				this.allData = null;
				this.allData = data;
			},


			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps);
			},


			handleChangePage(page) {
				this.updateParams({ page: page });
				// this.log.push(`The user changed the page to: ${page}`)
				this.loadItems();
			},


			handlePageChange(active) {
				this.updateParams({ perPage: active });
				this.log.push(`the user change page:  ${active}`);
				this.loadItems();
			},


			onSortChange(params) {
				this.updateParams({
					sort: params,
				});
				this.loadItems();
				this.log.push(`the user ordered:  ${params[0].type}`);
			},


			onColumnFilter(params) {
				this.updateParams(params);
				this.loadItems();
			},


			loadItems() {

				this.isLoadingProp = true;

				this.$http
				.post(this.get_main_data_url, {
					data: this.serverParams,
				})
				.then((response) => {
					this.totalRecords = response.data.totalRecords;
					this.rows = response.data.data;
					this.updateAllData(response.data.data);
					this.isLoadingProp = false;
				})
				.catch((error) => {
					this.isLoadingProp = false;
					this.toast('danger', error.message, 'Table Data Failed');
				});
			},

			/// TABLE METHODS END ///

			testInvoice(seller_payment_id) {

				if (seller_payment_id) {

					this.isLoadingProp = true;


					/// ---	REQUEST ( SEND INVOICE ) --- ///
					this.$http
					.post("seller-payment-test-invoice", {
						seller_payment_id: seller_payment_id,
					})
					.then((response) => {
						console.log(response);
						this.isLoadingProp = false;
						this.toast('success', response.data.message, 'Success');
					})
					.catch((error) => {
						this.isLoadingProp = false;
						this.toast('danger', error.message, 'Failed');
					});

				}
			},






			sendInvoice(seller_payment_id) {

				console.log(seller_payment_id);

				if (seller_payment_id) {

					this.isLoadingProp = true;


					/// ---	REQUEST ( SEND INVOICE ) --- ///
					this.$http
					.post("seller-payment-send-invoice", {
						seller_payment_id: seller_payment_id,
					})
					.then((response) => {
						console.log(response);
						this.isLoadingProp = false;
						this.toast('success', response.data.message, 'Success');
					})
					.catch((error) => {
						this.isLoadingProp = false;
						this.toast('danger', error.message, 'Failed');
					});

				}
			},


			toast(variant = null, message = 'Toast body content', title = variant) {
				
				message = this.$createElement('strong', { class: 'mr-2' }, message)

				this.$bvToast.toast(message, {
					title: title,
					variant,
					solid: false,
				})
			},

		},
	};


</script>

<style lang="scss">
	@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
